import React from "react"
import Header from "../components/header"
import Layout from "../components/layout"

const Resume = () => (
    <Layout>
      <h1>Resume</h1>
      <p>...will go here</p>
    </Layout>
)

export default Resume